<template>
  <!-- 首页 -->
  <div class="home">
    <div class="block">
      <div :class="['isFade'?'fade-in':'']">
        <h1>Lithium-Ion Battery Recycling: The Complete Guide</h1>
      </div>
    </div>
    <div class="actionBox">
      <div class="middle">
      <div class="middle-title">
        <h2 class="titleh2">Can You Recycle Lithium-Ion Batteries?</h2>
        <div class="bottomline"></div>
        <span>Yes, lithium-ion batteries are recyclable, but the process is a bit
          complicated. This might be the reason why you're struggling to find
          local recycling centers equipped to handle them.</span>
      </div>
      <div class="midlle-list">
        <div style="font-family: 'barlow';">
          You can't handle those batteries like any other electronic waste.They
          can get hot or<span class="fontBlod">
            spark a fire if damaged or improperly handled.</span>
        </div>
        <div style="font-family: 'barlow';">
          Properly recycling lithium-ion batteries is essential for<span class="fontBlod">
            safety and environmental protection.</span>
        </div>
        <div style="font-family: 'barlow';"> 
          lithium battery recycling<span class="fontBlod">
            reduces the need for new mineral extraction,</span>
          which is always a win for the environment.
        </div>
        <div style="font-family: 'barlow';">
          You cannot recycle lithium batteries indefinitely,Not everything in
          lithium batteries is recyclable.<span class="fontBlod">Never throw lithium batteries into the trash can.</span>
        </div>
      </div>
    </div>
    <div class="harms">
      <div>
        <h2 class="titleh2">Harmful Metals in The Lithium Batteries</h2>
        <div class="bottomline"></div>
      </div>
      <div class="harms-list">
        <div class="list-item">
          <img src="https://greencitizen.com/wp-content/uploads/2023/11/harmful-metals-in-lithium-ion-battery.jpg" alt=""
            class="scaleimg" />
        </div>
        <div>
          <span>Lithium-ion batteries are made up of a mix of materials.</span>
          <span>Depending on the brand, they typically contain<span class="fontBlod">-30% cobalt, 5-10% nickel, and 5-7%
              lithium.</span>Along with these metals, there are also about<span class="fontBlod"></span>
            15% organic chemicals and 7% plastics that make up the rest of the
            battery. Each manufacturer might use slightly different amounts, but
            this gives you a general idea of what's inside these
            batteries.</span>
        </div>
      </div>
    </div>
    <Swiper :swiperList="humpList"></Swiper>
    <div class="determine">
      <div class="fall-top">
        <h2 class="titleh2">
          How to Tell If Your Lithium-ion Battery Is Bad/Damaged?
        </h2>
        <div class="bottomline"></div>
      </div>
      <div class="determine-list">
        <div>
          <h3>Visual Inspection</h3>
          <span>A battery that appears
            <span class="fontBlod">swollen or has a bloated casing is a clear indicator</span>
            of damage. Swelling can be caused by gas buildup due to
            overcharging, deep discharging, or internal short circuit.
          </span>
        </div>
        <div>
          <h3>Performance Checks</h3>
          <span>If your<span class="fontBlod"> battery drains more quickly</span>
            than it used to, it's often a sign of degradation</span>
          <span>If the<span class="fontBlod">
              battery doesn't hold a charge at all or drops charge
              rapidly,</span>
            it's likely compromised.</span>
        </div>
        <div>
          <h3>Voltage Tests</h3>
          <span>Using a multimeter to<span class="fontBlod">
              check the battery's voltage</span>
            can tell you a lot. Batteries wear out over time, with their voltage
            dropping after repeated use. </span><span>A quick voltage check with a multimeter can tell you if your
            battery is still good or needs to be replaced.</span>
        </div>
        <div>
          <h3>Physical Sensations</h3>
          <span>Batteries that <span class="fontBlod">overheat during charging or use</span>
            can be dangerous. Internal resistance can cause a battery to heat
            up</span><span>A leaking battery or a strange chemical smell can indicate a
            breached battery cell, which is hazardous.</span>
        </div>
        <div>
          <h3>Electronic Testing</h3>
          <span>Professional testing of a
            <span class="fontBlod">battery's internal resistance</span> can
            reveal health. A rising resistance implies a decline in performance.
            Batteries with a resistance beyond the manufacturer's specification
            are considered damaged.</span>
        </div>
        <div>
          <h3>Software Diagnostics</h3>
          <span>Many modern devices come with
            <span class="fontBlod">software that reports battery health.</span></span><span>Some protective cases for
            phones include smart technology that can
            diagnose battery health and offer detailed data about charging
            cycles and overall battery efficiency.</span>
        </div>
      </div>
    </div>
    <div class="arcbox">
      <ArticCon :contentList="contentList"></ArticCon>
    </div>
    <div class="notice">
      <div class="fall-top">
        <h2 class="titleh2">
          Dos And Don'ts When Recycling Lithium-Ion Batteries
        </h2>
        <div class="bottomline"></div>
      </div>
      <div v-for="(item, index) in contentList[5].proDetail" :key="index">
        <div v-html="item.content" class="dois"></div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import contentList from "../../mock/content";
import ArticCon from "./components/ArticCon/index.vue";
import Swiper from "@/components/Swiper.vue";
import "animate.css";
export default {
  components: {
    ArticCon,
    Swiper,
  },
  data() {
    return {
      contentList,
      swiperList: [
        // {id:0,imgUrl:'https://greencitizen.com/wp-content/uploads/2023/08/Lithium-ion-battery-recycling.jpg'},
        // {id:1,imgUrl:'https://greencitizen.com/wp-content/uploads/2023/11/harmful-metals-in-lithium-ion-battery.jpg'},
        {
          id: 2,
          imgUrl:
            "https://greencitizen.com/wp-content/uploads/2023/11/lithium-ion-battery-for-EV.jpg",
        },
        // {id:3,imgUrl:'https://greencitizen.com/wp-content/uploads/2023/11/lithium-ion-battery-stacked.jpg'}
      ],
      humpList: [
        {
          id: 0,
          img: "https://greencitizen.com/wp-content/uploads/2023/11/lithium-ion-battery-in-landfill.jpg",
          title: "Human Toxicity from Damage and Deterioration",
          content: [
            {
              value: 1,
              detail:
                "<span style='display: block;'>Lithium-ion batteries pose a toxic threat. If they're damaged, they can release fine particles with aerodynamic diameters of less than 10 or 3.5 μm—known as<span style='font-weight:900;color:#127BC4;'> PM10 and PM1.5</span>—into the air. </span><span>These particles are especially harmful <span style='font-weight:900;color:#127BC4;'> heart and lung diseases, cancer risks, and hormonal imbalances.</span></span>",
            },
          ],
        },
        {
          id: 1,
          img: "https://greencitizen.com/wp-content/uploads/2023/11/damaged-lithium-ion-battery.jpg",
          title: "Potential Fire Hazard",
          content: [
            {
              value: 1,
              detail:
                "<span'>Veolia, one of the global waste handling companies has noted a<span style='font-weight:900;color:#127BC4;'> 38% increase in fire incidents</span> due to the presence of lithium-ion batteries in the waste stream. </span><span style='font-weight:900;color:#127BC4;'>If a charged lithium cell is crushed or pierced, it will short-circuit which also causes thermal runoff that leads to combustion or explosion.</span><span></span>",
            },
          ],
        },
        {
          id: 2,
          img: "https://greencitizen.com/wp-content/uploads/2023/11/Testing-lithium-ion-battery.jpg",
          title: "Groundwater Contamination Concerns",
          content: [
            {
              value: 1,
              detail:
                "When lithium-ion batteries end up in landfills, they can<span style='font-weight:900;color:#127BC4;'> create leachate, a toxic liquid</span> that forms when rainwater filters through waste material. This leachate can carry dangerous chemicals and metals from the batteries, seeping into and contaminating soil and groundwater.",
            },
          ],
        },
      ],
      observers: [],
      isFade:false
    };
  },
  methods: {
    isElementInViewport(element) {
      var rect = element.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight);
      return isInViewport;
    },
    checkHasAttribute(element) {
      return !!element.getAttribute("snow_is_show");
    },
    initFirstScreen() {
      const main = document.querySelectorAll(".home div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        if (this.isElementInViewport(item)) {
          item.setAttribute("snow_is_show", true);
        }
      });
    },
    animateFn() {
      const main = document.querySelectorAll(".actionBox div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.checkHasAttribute(item)) {
              // 元素进入视口
              item.classList.add("animate__animated");
              item.classList.add("animate__fadeInUp");
              item.setAttribute("snow_is_show", true);
            }
          });
        });
        observer.observe(item);
        this.observers.push(observer);
      });
    },
    // 清空所有 observer 的函数
    destructionObserver() {
      observers.forEach((observe) => {
        observe.disconnect();
      });
      observers.length = 0;
    },
    bgAction() {
      window.addEventListener('DOMContentLoaded', function () {
        var image = document.querySelector('.fade-in'); // 获取图片元素
        setTimeout(function () {
          this.isFade=true
        }, 500); // 延迟500ms后才开始显示图片
      });
      this.isFade=false
    }
  },
  mounted() {
    this.initFirstScreen();
    this.animateFn();
    this.bgAction();
  },
  watch: {
    '$route': function (newVal) {
      this.destructionObserver() // 先清空所有的观察者
      this.initFirstScreen() // 再初始化一次 类似onMounted
      this.animateFn() // 再次执行核心函数
      this.bgAction()
    }
  }
};
</script>

<style scoped>
.home {
  margin: 0 auto;
}

.top {
  /* background: url('https://greencitizen.com/wp-content/uploads/2023/08/Lithium-ion-battery-recycling.jpg') no-repeat; */
  /* background-size: cover;
  background-position: center; */
  height: 400px;
  color: rgb(229, 215, 215);
  display: flex;
  text-align: center;
  font-family: 'barlow';
}

.makebold {
  font-weight: 900;
  color: #127bc4;
  font-family: 'barlow';
}

.intr {
  /* max-width: 1211px; */
  margin: 0 auto;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000000;
  font-family: 'barlow';
  text-align: center;
}

.intr>div {
  cursor: pointer;
}

.intr-second {
  margin: auto;
}

.span-line {
  text-decoration: underline;
  cursor: pointer;
}

.arcbox {
  width: 1211px;
  margin: 0 auto;
}

.bottomline {
  width: 30px;
  height: 2px;
  background-color: rgb(18, 123, 196);
  margin: auto;
  margin-bottom: 20px;
}

.middle,
.harms,
.determine,
.notice {
  width: 1200px;
  margin: 0 auto;
}

.middle-title {
  text-align: center;
}

.titleh2 {
  margin-top: 50px;
  color: rgb(18, 123, 196);
  font-family: 'barlow';
}

.middle-title span {
  line-height: 30px;
}

.midlle-list {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.midlle-list>div {
  border: 1px solid #e9e9e9;
  flex: 1;
  margin: 20px;
  border-radius: 20px;
  padding: 20px;
  line-height: 30px;
}

.midlle-list>div:hover {
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.fontBlod {
  font-weight: 900;
  color: rgb(18, 123, 196);
  font-family: 'barlow';
}

.harms {
  text-align: center;
}

.harms-list {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.harms-list>div {
  flex: 1;
  margin: auto;
}

.list-item img {
  width: 90%;
  float: left;
}

.harms-list span {
  line-height: 30px;
  font-family: 'barlow';
}

.scaleimg {
  transition: transform 0.3s ease;
}

.scaleimg:hover {
  transform: scale(1.2);
}

.fall-top {
  text-align: center;
  margin-top: 50px;
  color: rgb(18, 123, 196);
  font-family: 'barlow';
}

.determine-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.determine-list>div {
  width: 290px;
  background: #e3f4ff;
  border-radius: 100px 4px 4px 4px;
  margin-top: 20px;
  padding: 15px;
}

.determine-list h3 {
  text-align: center;
  font-family: 'barlow';
}

.determine-list h3,
span {
  line-height: 30px;
  font-family: 'barlow';
}

.dois {
  margin-bottom: 50px;
}

.dois>span {
  line-height: 30px;
  font-family: 'barlow';
}

.block img {
  width: 100%;
  height: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  font-family: 'barlow';
}

.block {
  display: flex;
  height: 700px;

}

.block h1 {
  z-index: 100;
  color: #ffffff;
  margin: auto;
  line-height: 700px;
  font-family: 'barlow';
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  /* 起始位置 */
  100% {
    opacity: 1;
    transform: translateX(0);
  }

  /* 结束位置 */
}

.fade-in {
  animation: fadeIn 2s ease forwards;
  width: 100%;
  background: url("https://greencitizen.com/wp-content/uploads/2023/11/lithium-ion-battery-for-EV.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  height: 100%;
}
</style>