<template>
  <!-- 回收能力 -->
  <div class="recycle">
    <!-- 3 个简单步骤 -->
    <section class="elementor-top-section">
      <!-- 版心 -->
      <div class="elementor-widget-wrap">
        <!-- 1 -->
        <div class="elementor-widget-container">
          <h2>3 Easy Steps to an EPA Recycling Certifcate</h2>
        </div>
        <!-- 2 -->
        <div class="elementor-widget-secrod">
          <h2>We've been efficiently recycling for every battery type</h2>
          <!-- <h2 style="padding-left: 355px">for <u>over a decade</u>.</h2> -->
        </div>
        <!-- 3图 -->
        <div class="bottom">
          <!-- 1 -->
          <div class="elementor-element-populated">
            <div class="pic">
              <img
                src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/07/Schedule-Next-Day-Recycling-1.png"
                alt=""
                width="146"
                height="121"
              />
            </div>
            <h2>Schedule Next-Day Recycling</h2>
            <p>
              Upon acceptance of a quote, we will set up a pickup date/time. We
              ensure safety and ease with every pickup. Next-day pickup is our
              standard, so your batteries will be gone by tomorrow.
            </p>
          </div>
          <!-- 2 -->
          <div class="elementor-element-populated">
            <div class="pic">
              <img
                src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/07/We-Pick-up-your-batteries-2.png"
                alt=""
                width="146"
                height="121"
              />
            </div>
            <h2>We Pickup your Batteries</h2>
            <p>
              Our experienced logistics team creates all shipping paperwork and
              hazardous labeling when appropriate. We provide that to the client
              ahead of time and schedule a battery shipment according to their
              schedule.
            </p>
          </div>
          <!-- 3 -->
          <div class="elementor-element-populated">
            <div class="pic">
              <img
                src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/07/Receive-payment-proof-3.png"
                alt=""
                width="146"
                height="121"
              />
            </div>
            <h2>Receive Payment & Proof</h2>
            <p>
              Once the final battery weight is confirmed, we will quickly and
              efficiently issue your payment and recycling certificate. You
              deserve to get paid as quickly as we recycle.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 成功案例 -->
    <section class="elementor-section-boxed">
      <div class="left">
        <h2>Success Stories</h2>
        <div class="plus-list">
          <h3>
            We're&nbsp;<span style="border-bottom: 6px solid #bed5e5"
              >trusted</span
            >&nbsp;by industry leaders
          </h3>
        </div>
        <p>
          From answering any questions you may have, working to create a
          strategy that best fits your goals, and making sure no detail is
          overlooked, our number one goal is to provide our clients with a
          seamless experience. Earning your trust is the biggest compliment we
          can receive, so we make sure no stone is left unturned.
        </p>
      </div>
      <div class="right">
        <div class="top">
          <i style="color: #3575d3; font-size: 60px; font-weight: 900">“</i>
        </div>
        <div class="word">
          <p>
            "The most obvious advantage to working with Battery Recyclers of
            America is their responsiveness and ability to solve logistical
            problems. They are also very competitive with pricing and really
            take care of their clients."
          </p>
          <div class="contect">
            <h2>John A.</h2>
            <span> Project Manager,</span>
            <p>Critical Power</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 电池类型 -->
    <section class="battery">
      <h2>Every Battery, No Exceptions</h2>
      <h1>
        We recycle
        <u style="text-decoration: none; border-bottom: 6px solid #beeacb">
          every</u
        >
        battery type.
      </h1>
      <div class="tag">
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/12/Absolyte-1.jpg"
              alt=""
            />
          </div>
          <h2>Absolyte Battery</h2>
        </div>
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2022/01/Valence-1-1.jpg"
              alt=""
            />
          </div>
          <h2>Lithium Iron Phosphate (LFP)</h2>
        </div>
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/12/Lead-Acid-3.jpg"
              alt=""
            />
          </div>
          <h2>Lead Acid Battery</h2>
        </div>
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/12/UPS-whole-unit-1.jpg"
              alt=""
            />
          </div>
          <h2>UPS</h2>
        </div>
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2021/12/nicad-wet-3.jpg"
              alt=""
            />
          </div>
          <h2>Wet Nicad</h2>
        </div>
        <div class="type">
          <div class="pag">
            <img
              src="https://cdn-fdlgl.nitrocdn.com/sBioAJiXTzOQPoGbVfSWHvimwfVxVxof/assets/images/optimized/rev-6399f80/www.batteryrecyclersofamerica.com/wp-content/uploads/2022/02/Lithium-Ion-EV-2.jpg"
              alt=""
            />
          </div>
          <h2>EV Battery</h2>
        </div>
      </div>
    </section>

    <!--行业需求 -->
    <section class="industry">
      <div class="industry-left">
        <h2>Our Portfolio Continues to Grow</h2>
        <h1>
          We understand that <br />every industry is
          <u style="text-decoration: none; border-bottom: 6px solid #beeacb"
            >unique</u
          >
        </h1>
        <p>
          Every industry has different needs, uses different <br />batteries,
          and requires different solutions. We serve <br />from small private
          businesses, to large industries
        </p>
       
      </div>
      <div class="industry-right">
        <!-- <h2>Choose your industry below</h2> -->
        <div class="below">
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求1.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Mission Critical <br />
              Backup Power
            </h2>
          </div>
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求2.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Energy <br />Storage
            </h2>
          </div>
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求3.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Telecom & <br />
              Cellular
            </h2>
          </div>
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求4.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Warehouse <br />
              Teams
            </h2>
          </div>
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求5.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Government or <br />
              Municipalities
            </h2>
          </div>
          <div class="every">
            <img
              src="../../../src/assets/image/行业需求6.png"
              alt=""
              width="100px"
              height="100px"
            />
            <h2 style="color: #63ba78; font-size: 13px">
              Water Meters <br />
              & Devices
            </h2>
          </div>
        </div>
      </div>
    </section>

    <!-- 合作 -->
    <section class="easiest">
      <h2>We're the easiest in the industry to work with.</h2>
      <div class="numberbox">
        <div class="numbercounter">
          <h5><span>98</span><span>%</span></h5>
          <h6>On Time Rate</h6>
        </div>
        <div class="numbercounter">
          <h5><span>1.5</span></h5>
          <h6>Days from Call to Pickup</h6>
        </div>
        <div class="numbercounter">
          <h5><span>19.3</span><span>k</span></h5>
          <h6>Happy Clients Since 2019</h6>
        </div>
      </div>
    </section>

    <!-- 定价 -->
    <section class="pricing">
      <div class="pricing-typepage">
        <div class="pricing-left">
          <h2>Competitive & Fair Pricing</h2>
          <h1>
            We work hard to give our <br />
            customers the best recycling <br />
            rates in the industry.
          </h1>
        </div>
        <!-- 右侧 -->
        <div class="pricing-right">
          <div class="pricing-right-top pricingitem">
            <!-- 图 -->
            <div class="pricing-right-top-image priconce">
              <img src="../../assets/image/定价1.png" alt="" />
            </div>
            <!-- 文字 -->
            <div class="pricing-right-top-word">
              <h2>
                We have the best Lithium, Telecom, <br />and UPS Battery
                Recycling Rates
              </h2>
              <p>
                Get paid the same day for all full <br />
                truckload shipments of recycled materials
              </p>
            </div>
          </div>
          <div class="pricing-right-bottom pricingitem">
            <!-- 图 -->
            <div class="pricing-right-top-image pricsec">
              <img src="../../assets/image/定价2.png" alt="" />
            </div>
            <!-- 文字 -->
            <div class="pricing-right-top-word">
              <h2>Same Day Full Truck Load Payments</h2>
              <p>
                Get paid the same day for all full <br />
                truckload shipments of qualifying <br />
                recycled materials
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 物流 -->
    <section class="logistics">
      <div class="pickup">
        <h2>Next-Day Pickup is Our Standard</h2>
        <h1>Pickup is always on us.</h1>
        <p>
          We do not have dropoff locations. Your experienced logistics team
          <br />
          creates all shipping paperwork and hazardous labeling when <br />
          appropriate. We provide that to the client ahead of time and schedule
          <br />
          a battery shipment according to their schedule.
        </p>
      </div>
    </section>

    <!-- 解决方案 -->
    <section class="solutions">
      <div class="solutions-wrap">
        <h2>We have a solution that's perfect for you.</h2>
        <p>
          <strong>Need an NDA?</strong> No problem. Your privacy matters to us.
        </p>
        <div class="solutionsbox">
          <div class="small" style="width: 558px; height: 442px">
            <div class="smallpage">
              <h2 style="color: white">Quick Start</h2>
              <h1>
                Small Commercial <br />
                Pickup Solutions
              </h1>
              <p style="color: white">
                This choice is best for your company if <br />you need 1-4
                pickups per year.
              </p>
            </div>
          </div>
          <div class="large" style="width: 558px; height: 442px">
            <div class="largepage">
              <h2>Co-Create</h2>
              <h1 style="color: #000">
                Large Commercial <br />
                Pickup Solutions
              </h1>
              <p>
                This choice is best for your company if <br />you need 4+
                pickups per year.
              </p>
            </div>
          </div>
        </div>
        <h1 style="font-family: 'barlow';">
          <strong
            >Looking to recycle a smaller quantity of batteries? Check out our
            bucket program</strong
          >
        </h1>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data(){
    return{
     observers: [], 
    }
  },
  methods: {
    isElementInViewport(element) {
      console.log("15", element);
      var rect = element.getBoundingClientRect();
      console.log("16", rect);
      const isInViewport =
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight);
      console.log("17", isInViewport);
      return isInViewport;
    },
    checkHasAttribute(element) {
      return !!element.getAttribute("snow_is_show");
    },
    initFirstScreen() {
      const main = document.querySelectorAll(".recycle div") || [];
      console.log("结果", main);
      const paragraphs = [...(main || [])];
      console.log("12", paragraphs);
      paragraphs.forEach((item) => {
        if (this.isElementInViewport(item)) {
          console.log("14");
          item.setAttribute("snow_is_show", true);
        }
      });
    },
    animateFn() {
      const main = document.querySelectorAll(".recycle div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.checkHasAttribute(item)) {
              // 元素进入视口
              item.classList.add("animate__animated");
              item.classList.add("animate__fadeInUp");
              item.setAttribute("snow_is_show", true);
            }
          });
        });
        observer.observe(item);
        this.observers.push(observer);
      });
    },
    // 清空所有 observer 的函数
    destructionObserver() {
      observers.forEach((observe) => {
        observe.disconnect();
      });
      observers.length = 0;
    },
  },
   mounted() {
    this.initFirstScreen()
    this.animateFn()
  },
   watch:{
    '$route':function(newVal){
      console.log('222',newVal);
      this.destructionObserver() // 先清空所有的观察者
     this.initFirstScreen() // 再初始化一次 类似onMounted
     this.animateFn() // 再次执行核心函数
    }
  }
};
</script>

<style scoped>
/* 头部log */
.elementor-section {
  height: 136px;
  display: flex;
  justify-content: center;
  .elementor-widget-wrap {
    width: 1000px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/*  3 个简单步骤 */
.elementor-top-section {
  height: 670px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .elementor-widget-wrap {
    width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .elementor-widget-container {
      margin: 0 auto;
      h2 {
        color: #071f33;
        font-size: 1rem;
        font-weight: 300px;
        text-transform: uppercase;
        line-height: 1.7em;
        letter-spacing: 1px;
        font-family: 'barlow';
      }
    }
    .elementor-widget-secrod {
      margin: 0 auto;
      h2 {
        color: #071f33;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.4em;
        letter-spacing: 1px;
        font-family: 'barlow';
        u {
          text-decoration: none;
          border-bottom: 6px solid #beeacb;
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      .elementor-element-populated {
        display: flex;
        flex-direction: column;
        .pic {
          width: 200px;
          height: 200px;
          background-color: #def7e6;
          margin: 0 auto;
          border-radius: 50%;
          position: relative;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        h2 {
          padding-top: 20px;
          font-size: 24px;
          color: #000;
          font-weight: 600;
          line-height: 1.6em;
          font-family: 'barlow';
        }
        p {
          padding-top: 20px;
          width: 350px;
          height: 172px;
          margin: 0 0 14px;
          font-size: 17px;
          color: #585858;
          font-weight: 300;
          line-height: 1.5em;
          text-align: left;
          font-family: 'barlow';
        }
      }
    }
  }
}

/* 成功案例 */
.elementor-section-boxed {
  height: 400px;
  background-color: #e3f3fa;
  margin: 10px 0 0;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  .left {
    width: 450px;
    height: 440px;
    h2 {
      color: #071f33;
      font-size: 1rem;
      font-weight: 300px;
      text-transform: uppercase;
      line-height: 1.7em;
      letter-spacing: 1px;
      font-family: 'barlow';
    }
    .plus-list {
      margin-top: 15px;
      h3 {
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 1.4em;
        letter-spacing: 1.5px;
        word-spacing: 1px;
        font-family: 'barlow';
      }
    }
    p {
      margin-top: 15px;
      font-size: 16px;
      color: #585858;
      font-size: 1rem;
      font-weight: 400px;
      line-height: 1.7em;
      letter-spacing: 1px;
      font-family: 'barlow';
    }
  }
  .right {
    border-left: 2px solid #bed5e5;
    width: 600px;
    height: 374px;
    margin-left: 0;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .word {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.4em;
      color: #70767b;
      letter-spacing: 1px;
      font-family: 'barlow';
      p {
        padding-left: 40px;
        font-family: 'barlow';
      }
      .contect {
        h2 {
          font-weight: 600;
        }
        padding-left: 40px;
        margin-top: 20px;
        font-size: 14px;
        font-family: 'barlow';
      }
    }
  }
}

/* 电池类型 */
.battery {
  width: 1200px;
  height: 720px;
  margin: 0 auto;
  h2 {
    color: #071f33;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7em;
    margin: 40px 0 10px 200px;
    font-family: 'barlow';
  }
  h1 {
    font-size: 34px;
    font-weight: 600px;
    letter-spacing: 1px;
    margin: 20px 0 10px 200px;
    font-family: 'barlow';
  }
  .tag {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .type {
      width: 380px;
      height: 280px;
      background-color: #def7e6;
      margin-top: 20px;
      border-radius: 10%;
      h2 {
        margin: 0 auto;
        font-family: 'barlow';
      }
      .pag {
        width: 400px;
        height: 210px;
        position: relative;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
      }
      h2 {
        color: #3575d3;
        font-size: 23px;
        line-height: inherit;
        font-weight: 500;
        text-align: center;
        margin-top: 20px;
        font-family: 'barlow';
      }
    }
  }
  .click {
    /* padding-top: 20px; */
    height: 200px;
    background-color: #3575d3;
  }
}

/* 行业需求  */
.industry {
  height: 490px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  .industry-left {
    margin-top: 30px;
    width: 600px;
    height: 446px;
    h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2em;
      letter-spacing: 1.2px;
      font-family: 'barlow';
    }
    h1 {
      margin-top: 20px;
      font-size: 38px;
      font-weight: 600px;
      letter-spacing: 1.2px;
      line-height: 1.2em;
      font-family: 'barlow';
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5em;
      letter-spacing: 1.2px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: 'barlow';
    }
    el-button {
      margin-top: 20px;
    }
  }
  .industry-right {
    padding-top: 50px;
    width: 600px;
    /* margin-top: 10px; */

    h2 {
      font-size: 20px;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      font-family: 'barlow';
    }
    .below {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 10px;
      width: 600px;
      height: 400px;
      .every {
        width: 170px;
        height: 170px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

        img {
          padding-left: 36px;
          padding-top: 5px;
        }
        h2 {
          /* margin-top: 2px; */
          /* padding-left: 10px; */
          font-size: 16px;
          font-weight: 300;
          line-height: 1.2em;
          letter-spacing: 1.2px;
          font-family: 'barlow';
        }
      }
    }
  }
}

/* 合作 */
.easiest {
  height: 340px;
  background-color: #e3f3fa;
  h2 {
    font-size: 38px;
    font-weight: 500;
    line-height: 1.3em;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 0;
    font-family: 'barlow';
  }
  .numberbox {
    display: flex;
    justify-content: center;

    margin: 0 auto;
    .numbercounter {
      width: 360px;
      height: 207px;
      margin-top: 35px;
      margin-left: 40px;
      /* background-color: aqua; */
      span {
        font-size: 96px;
        color: #24974a;
        display: inline-block;
        margin: 0 auto;
        /* font-family: 'barlow'; */
      }
      h6 {
        font-size: 19px;
        margin: 40px 0;
        /* font-family: 'barlow'; */
      }
    }
  }
}

/* 定价 */
.pricing {
  height: 400px;
  .pricing-typepage {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .pricing-left {
      width: 600px;
      height: 350px;
      padding-top: 40px;
      h2 {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2em;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        font-family: 'barlow';
      }
      h1 {
        margin-top: 20px;
        font-size: 38px;
        font-weight: 600px;
        letter-spacing: 1.2px;
        line-height: 1.2em;
        font-family: 'barlow';
      }
    }
    .pricing-right {
      width: 600px;
      height: 350px;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      .pricingitem {
        display: flex;
        justify-content: center;
      }
      .pricing-right-top {
        border-top: 2px solid #afbcc7e4;
        border-bottom: 2px solid #afbcc7e4;
      }
      .pricing-right-bottom {
        border-bottom: 2px solid #afbcc7e4;
      }
      .pricing-right-top-image {
        width: 180px;
        height: 140px;
      }
      .pricing-right-top-word {
        width: 400px;
        height: 138px;
        h2 {
          padding-top: -8px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.4em;
          font-family: 'barlow';
        }
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 1.4em;
          letter-spacing: 1.2px;
          padding-top: -4px;
          font-family: 'barlow';
        }
      }
      .priconce img {
        margin-top: 20px;
        margin-left: 30px;
        width: 90px;
        height: 45px;
      }
      .pricsec img {
        margin-top: 15px;
        margin-left: 30px;
        width: 71px;
        height: 120px;
      }
    }
  }
}

/* 物流 */
.logistics {
  height: 240px;
  .pickup {
    h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2em;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      text-align: center;
      font-family: 'barlow';
    }
    h1 {
      margin-top: 20px;
      font-size: 38px;
      font-weight: 600px;
      letter-spacing: 1.2px;
      line-height: 1em;
      text-align: center;
      font-family: 'barlow';
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.4em;
      letter-spacing: 1.2px;
      text-align: center;
      margin-top: 20px;
      font-family: 'barlow';
    }
  }
}

/* 解决方案 */
.solutions {
  height: 680px;
  background-color: #e3f3fa;
  .solutions-wrap {
    width: 1200px;
    height: 640px;
    margin: 0 auto;
    h2 {
      margin-top: 20px;
      font-size: 38px;
      font-weight: 400;
      letter-spacing: 1.2px;
      line-height: 1em;
      text-align: center;
      padding-top: 40px;
      font-family: 'barlow';
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.4em;
      letter-spacing: 1.2px;
      text-align: center;
      margin-top: 20px;
      font-family: 'barlow';
    }
    .solutionsbox {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      h2 {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2em;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        text-align: center;
        font-family: 'barlow';
      }
      h1 {
        margin-top: 10px;
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 1.4em;
        text-align: center;
        padding-top: 20px;
        color: #e3f3fa;
        font-family: 'barlow';
      }
      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.4em;
        letter-spacing: 1.2px;
        text-align: center;
        margin-top: 20px;
        font-family: 'barlow';
      }
      .small {
        background-color: #3575d3;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
        margin-right: 20px;
      }
      .large {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      }
    }
    h1 {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.4em;
      letter-spacing: 1.2px;
      text-align: center;
      margin-top: 20px;
      font-family: 'barlow';
    }
  }
}
</style>
