<template>
  <div class="my-header">
    <div class="headerbox">
      <div class="center">
        <div class="parent">
          <!-- 大logo区 -->
          <div class="logo" @click="backHome">
            <img src="../../public/logo_1.png" alt="" />
            <div style="height:50px">
              <div class="span">We</div>
              <div class="span">GreenStar</div>
            </div>
          </div>
          <!-- 导航栏 -->
          <div class="nav">
            <router-link
              v-for="(item, index) in headerList"
              :key="index"
              :to="item.path"
              ><span
                :class="currentIndex === index ? 'spanActive' : ''"
                @click="changeMenu(index)"
                @mouseenter="enterMenu(index)"
                >{{ item.menu }}</span
              ></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerList: [
        { id: 0, path: "/", menu: "Recovery" },
        { id: 1, path: "/recovrey", menu: "Service" },
        { id: 2, path: "/storage", menu: "Storage" },
        { id: 3, path: "/transport", menu: "Transportation" },
      ],
      currentIndex: -1,
    };
  },
  methods: {
    changeMenu(index) {
      this.currentIndex = index;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    enterMenu(index) {
      this.currentIndex = index;
    },
    enterMenu(index) {
      this.currentIndex = index;
    },
    backHome() {
      this.$router.push("/");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.my-header {
  height: 80px;
  z-index: 999 !important;
}

.headerbox {
  z-index: 999 !important;
}

.headerbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #373737;
  z-index: 999;
}

.center {
  width: 1211px;
  margin: 0 auto;
}

.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 80px;
  vertical-align: middle;
}

.logo .span {
  color: #A7DC4F;
  margin-left: 10px;
  font-size: 23px;
  font-family: "barlow";
  text-align: center;
}

.nav {
  background-color: #373737;
}

.nav span {
  line-height: 80px;
  padding: 10px;
  color: white;
  z-index: 999;
  font-family: "barlow";
  font-size: 16px;
}

.nav_search {
  width: 200px;
  height: 40px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}

.search_area {
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  transition: 1.5s width;
  border-radius: 30px;
  display: flex;
  overflow: hidden;
}

.search_area input {
  font-size: 16px;
}

.search_area:hover {
  width: 200px;
}

.icon {
  position: absolute;
  right: 0;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #545c64;
  font-size: 23px;
  font-family: "barlow";
}

.icon:hover {
  background-color: rgba(0, 0, 0, 1);
  transition: 2s background-color, 2s color;
  color: white;
  font-family: "barlow";
}

.reg {
  margin-left: auto;
  margin-right: 0px;
  position: relative;
  line-height: 60px;
  font-family: "barlow";
}

a {
  color: white;
  text-decoration: none;
  padding: 3px;
  font-size: 12px;
  font-family: "barlow";
}

.login {
  cursor: pointer;
  user-select: none;
  color: white;
  font-size: 20px;
  display: flex;
}

.login div:nth-child(1) {
  margin-right: 20px;
}

.show {
  position: absolute;
  width: 150px;
  height: 120px;
  background-color: rgba(2, 0, 0, 0.4);
  z-index: 999;
  text-align: center;
  top: 70px;
  right: -30px;
}

.show span {
  display: block;
  font-size: 20px;
  color: white;
  cursor: pointer;
  font-family: "barlow";
}

.nav span:hover {
  color: rgb(18, 123, 196);
  border-bottom: 2px solid rgb(18, 123, 196);
  font-family: "barlow";
}
</style>