import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import '../public/reset.css'
import './fonts/font.css'


//全局优雅引入
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

axios.defaults.baseURL = "http://127.0.0.1:3000/user";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import VeuAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VeuAwesomeSwiper)

import moment from "moment"
Vue.prototype.moment=moment

import VueAMap from "vue-amap";
Vue.use(VueAMap);

// 初始化vue-amap
VueAMap.initAMapApiLoader({
	key: "7f5d3a30470d83bcf143255553bfc430",// 高德的key
	// 插件集合
	plugin: [
		'AMap.CircleEditor',// 圆形编辑器插件
		"AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
		"AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.CitySearch",
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: '1.4.4'
});
// //高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:"579ec3d96f117ced36a40a010763d860",
}
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
