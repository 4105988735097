<template>
  <!-- <div class="container">
    <div class="arcbox" v-for="(item, index) in contentList" :key="index">
      <div class="arctitle">
        <div class="line"></div>
        <div>
          <h2 style="color: #127BC4;">{{ item.proTitle }}</h2>
        </div>
      </div>
      <div class="arcont">
        <div class="detail">
          <img :src="item.img" alt="" :style="{ float: item.dre, 'margin-right': item.mg }">
          <div v-for="(ite, index) in item.proDetail" :key="index" v-html="ite.content" class="writings"></div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">
    <div>
      <h2 class="title">How To Recycle Lithium-Ion Batteries</h2>
      <div class="bottomline"></div>
      <div class="list">
        <div class="detail">
          <div><h3>Find a Recycling Center</h3><h3>Prepare for Recycling</h3><h3>Understand the Process</h3><h3>Environmental Safety</h3><h3>Corporate Responsibility</h3><h3>Stay Informed</h3><h3>The One and Only Right Way</h3></div>
        </div>
        <div class="imgbox">
          <img src="https://greencitizen.com/wp-content/uploads/2023/11/recycling-lithium-ion-battery-for-cars.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['contentList'],
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.title{
  margin-top: 50px;
  color: rgb(18, 123, 196);
  text-align: center;
  font-family: 'barlow';
}
.list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.detail {
  flex: 1;
  border-radius: 100px 0 0 0;
  background-color: #fafafa;
  line-height: 30px;
      padding-top: 25px;
}
.detail h3{
  margin-left: 50px;
  font-family: 'barlow';
}

.imgbox {
  flex: 1;
}

.imgbox img {
  width: 100%;
  border-radius: 0 100px 0 0;
  transition: transform 0.3s ease;
}
.imgbox img:hover{
  transform: scale(1.1);

}
.bottomline {
  width: 30px;
  height: 2px;
  background-color: rgb(18, 123, 196);
  margin: auto;
  margin-bottom: 20px;
}


/* .arcbox {
  border-bottom: 1px solid #d6e1e7;
  padding-bottom: 10px;
}

.detail {
  line-height: 30px;
}

.arctitle {
  display: flex;
}

.line {
  width: 15px;
  height: 15px;
  margin: auto 0;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #127BC4;
}

.detail img {
  width: 50%;
  transition: transform 0.3s ease;
}
.detail img:hover{
  transform: scale(1.1);
}

.writings>span {
  display: block;
} */</style>