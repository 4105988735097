<template>
  <div class="swiper">
    <el-carousel :interval="4000" type="card" height="400px">
      <el-carousel-item v-for="(item, index) in swiperList" :key="index">
        <div>
          <div class="swiper-title">
            <h2>{{ item.title }}</h2>
          </div>
          <div class="swiper-cont">
            <div>
              <img :src="item.img" alt="">
            </div>
            <div>
              <span v-for="(ite, index) in item.content" :key="index" v-html="ite.detail"></span>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default{
props:["swiperList"]
}
</script>

<style scoped>
.swiper {
  width: 1200px;
  margin: 50px auto;
}

.swiper-cont {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
}

.swiper-cont>div {
  flex: 1;
  margin: 10px;
}

.swiper-title {
  text-align: center;
}

.swiper-cont span {
  line-height: 30px;
  font-family: 'barlow';
}

.swiper-cont img {
  width: 100%;
  height: 300px;
  border-radius: 6px;
}

.el-carousel__item {
  border-radius: 20px;
}

.el-carousel__item {
  background-color: #e3f4ff;
}

.el-carousel__item h3 {
  /* color: #475669; */
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  font-family: 'barlow';
}
</style>