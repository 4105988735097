<template>
  <!-- 运输能力 -->
  <div class="transport">
    <div :class="['isFade'?'fade-in':'']" class="bg">
      <h1>Transport</h1>
    </div>
    <div class="actionBox">
      <div class="intro">
      <h2>Lithium-Ion Battery Transportation – Before & During Recovery Events</h2>
      <div class="intro-detail">The Americase team understands that lithium batteries require careful consideration and
        attention-to-detail when shipping, especially if you're in the middle of recalling your products. That's why we've
        created various transportation cases specifically made to securely handle lithium batteries and withstand rigorous
        shipping conditions with the highest level of safety. This way, both you and your customers can rest easy knowing
        your lithium items are getting where they need to be without an unexpected event getting in the way.</div>
    </div>
    <Swiper :swiperList="swiperList"></Swiper>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper.vue';
export default {
  components:{
    Swiper
  },
  data() {
    return {
      swiperList: [
        { id: 0, img: 'https://americase.com/wp-content/uploads/2022/11/659A4922-min.jpg', title: 'The Battery Super Box', content: [{ value: 1, detail: 'Our Battery Super-Box was designed to be the most thermally protective lithium-ion containment and shipping solution in the market. Created with industrial strength aluminum capable of containing thermal-runaway events, this customizable lithium transportation product is here for you.' }] },
        { id: 1, img: 'https://americase.com/wp-content/uploads/2022/11/Battery-Bag-1.png', title: 'Our Ready-to-Use Solutions', content: [{ value: 1, detail: 'At Americase, we offer a variety of options for clients in need of immediate use of lithium-ion battery shipping and transportation: the Battery Bag, the Battery Box, and our Foil-Lined Battery Box.' }, { value: 2, detail: 'our ready-made shipping options are available now in a selection of sizes, or can be made to your specifications. ' }] }, { id: 2, img: 'https://americase.com/wp-content/uploads/2022/11/659A4787-min.jpg', title: 'Reusable BBU Transportation', content: [{ value: 1, detail: 'We engineered a customizable solution that reduces your overall BBU transportation costs, decreases the likelihood of staff injury, and helps to reduce your environmental impact.' }] }
      ],
      observers: [],
      isFade:false
    }
  },
   methods: {
    isElementInViewport(element) {
      var rect = element.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight);
      return isInViewport;
    },
    checkHasAttribute(element) {
      return !!element.getAttribute("snow_is_show");
    },
    initFirstScreen() {
      const main = document.querySelectorAll(".actionBox div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        if (this.isElementInViewport(item)) {
          item.setAttribute("snow_is_show", true);
        }
      });
    },
    animateFn() {
      const main = document.querySelectorAll(".actionBox div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.checkHasAttribute(item)) {
              // 元素进入视口
              item.classList.add("animate__animated");
              item.classList.add("animate__fadeInUp");
              item.setAttribute("snow_is_show", true);
            }
          });
        });
        observer.observe(item);
        this.observers.push(observer);
      });
    },
    // 清空所有 observer 的函数
    destructionObserver() {
      observers.forEach((observe) => {
        observe.disconnect();
      });
      observers.length = 0;
    },
    bgAction() {
      window.addEventListener('DOMContentLoaded', function () {
        var image = document.querySelector('.fade-in'); // 获取图片元素
        setTimeout(function () {
          this.isFade=true
        }, 500); // 延迟500ms后才开始显示图片
      });
      this.isFade=false
    }
  },
   mounted() {
    this.initFirstScreen()
    this.animateFn()
    this.bgAction()
  },
   watch:{
    '$route':function(newVal){
      this.destructionObserver() // 先清空所有的观察者
     this.initFirstScreen() // 再初始化一次 类似onMounted
     this.animateFn() // 再次执行核心函数
     this.bgAction()
    }
  }
}
</script>

<style scoped>
.bg {
  background: url('../../assets/image/transfer.png') no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  display: flex;
}

.bg h1 {
  color: #ffffff;
  margin: auto;
  font-family: 'barlow';
}

.intro {
  width: 1200px;
  margin: 50px auto;
}

.intro h2 {
  color: #127BC4;
  font-family: 'barlow';
}

.intro-detail {
  line-height: 30px;
  font-family: 'barlow';
}
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
  
    /* 起始位置 */
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  
    /* 结束位置 */
  }
  
  .fade-in {
    animation: fadeIn 2s ease forwards;
    width: 100%;
    background: url("../../assets/image/transfer.png") no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    height: 100%;
  height: 500px;

  }
</style>