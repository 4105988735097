<template>
	<div>
		<Myheader></Myheader>
	<RouterView></RouterView>
	<MyFooter></MyFooter>
	</div>
</template>
<script>
import Myheader from './components/Myheader.vue';
import MyFooter from './components/MyFooter.vue';
export default{
	components:{
		Myheader,
		MyFooter
	},
	mounted(){
    document.body.scrollTop=document.documentElement.scrollTop=0
	}
}
</script>

<style></style>
