<template>
  <!-- 存储能力 -->
  <div class="storage">
    <!-- logo -->
    <div class="logo"></div>

    <!-- 产品图 -->
    <div :class="['isFade'?'fade-in':'']">
      <img
        src="../../../src/assets/image/产品1.jpg"
        alt=""
        width="1250px"
        height="450px"
      />
    </div>
    <div class="actionBox">
      
    <!-- 应用 -->
    <div class="application">
      <h1>
        <i class="el-icon-magic-stick" style="color: rgb(25, 47, 214)"></i
        >Battery Storage Applications
      </h1>
      <p>
       When storing batteries, it's important to use the safest and most dependable batteries storage buildings available. <br />
        We offer many different chemical storage applications for lockers, buildings, and accessories to fit your needs.
      </p>
      <h2>A Building for Every Chemical Storage Application</h2>
      <div class="buliding">
        <div class="construction">
          <h2>Hazardous Material Storage Buildings</h2>
          <p>
            Hazmat exists in liquid, solid, or gas form and can be anything from
            paint to acids. All customizable hazardous material storage
            buildings meet EPA, OSHA, NFPA, and more. Both fire-rated and
            non-fire rated buildings are available.
          </p>
        </div>
        <div class="construction">
          <h2>Chemical Mixing Storage Building</h2>
          <p>
            Safely mixing and dispensing hazardous chemicals is a daily
            necessity for many manufacturers, the process releases hazardous
            fumes into the atmosphere. Separate hazardous processes from the
            normal working environment by using pre-engineered mixing and
            dispensing buildings as process isolation rooms.
          </p>
        </div>
        <div class="construction">
          <h2>Lithium Ion Battery Storage Buildings</h2>
          <p>
            Li-ion batteries contain lithium metal, which is highly combustible.
            Poor battery design, electrical shorting, overcharging, rapid
            discharge, or increased storage temperatures can cause li-ion
            batteries to overheat and ignite, posing a fire hazard.
          </p>
        </div>
        <div class="construction">
          <h2>Pallet Mixing & Dispensing Drum Storage</h2>
          <p>
            Separate hazardous processes from the normal working environment by
            using pre-engineered mixing and dispensing buildings as process
            isolation rooms. Built for your specific application, buildings
            comply with NFPA, IFC, OSHA, and EPA safety regulations.
          </p>
        </div>
      </div>
    </div>

    <!-- 所有应用 -->
    <div class="Allapplication">
      <h1>All Applications</h1>
      <div class="useflex">
        <!-- 1
        <div class="appliitem row-one">
          <div class="imgbox">
            <img src="../../assets/image/应用1.webp" alt="" />
          </div>
          <h2>Agricultural Chemical Storage Building</h2>
          <p>
            U.S. Chemical Storage engineers safe, secure storage for turf
            maintenance and agricultural professionals.
          </p>
        </div> -->
        <!-- 2 -->
        <!-- <div class="appliitem row-one">
          <div class="imgbox">
            <img src="../../assets/image/应用2.webp" alt="" />
          </div>
          <h2>Chemical Mixing and Dispensing Storage Building</h2>
          <p>
            Separate hazardous processes from the normal working environment by
            using pre-engineered mixing and dispensing buildings as process
            isolation rooms.
          </p>
        </div> -->
        <!-- 3 -->
        <!-- <div class="appliitem row-one">
          <div class="imgbox">
            <img src="../../assets/image/3.webp" alt="" />
          </div>
          <h2>Drum Storage Buildings</h2>
          <p>
            Safely contain hazardous material drums with our DrumLoc™, ChemLoc™,
            FireLoc™, or SuperLoc™ drum storage buildings from U.S. Chemical
            Storage.
          </p>
        </div> -->
        <!-- 4 -->
        <div class="appliitem row-two">
          <div class="imgbox">
            <img src="../../assets/image/4.webp" alt="" />
          </div>
          <h2>Hazardous Material Storage Buildings</h2>
          <p>
            U.S. Chemical Storage offers customizable hazardous material storage
            buildings that can meet EPA, NFPA, IBC, IFC codes and regulations
            and FM or Warnock Hersey approvals.
          </p>
        </div>
        <!-- 5 -->
        <div class="appliitem row-two">
          <div class="imgbox">
            <img src="../../assets/image/5.webp" alt="" />
          </div>
          <h2>Lithium Ion Battery Storage Building</h2>
          <p>
            Special storage precautions protect workers and the environment.
            U.S. Chemical Storage manufactures prefabricated lithium battery
            storage buildings designed specifically for storing li-ion
            batteries.
          </p>
        </div>
        <!-- 6 -->
        <!-- <div class="appliitem row-two">
          <div class="imgbox">
            <img src="../../assets/image/6.jpg" alt="" />
          </div>
          <h2>Paint Storage Buildings</h2>
          <p>
            Flammable paint storage buildings offer compliant, temperature
            controlled, paint storage solutions while providing unmatched
            strength and security to your valuable paint and supplies. All
            prefabricated buildings are made from heavy-gauge metal steel
            construction for exceptional durability and are available with a
            2-hour or 4-hour fire rating.
          </p>
        </div> -->
        <!-- 7 -->
        <!-- <div class="appliitem row-one">
          <div class="imgbox">
            <img src="../../assets/image/7.webp" alt="" />
          </div>
          <h2>Pallet and Tote Chemical Storage Buildings</h2>
          <p>
            When storing and organizing palletized drums and chemical totes of
            non-flammable chemicals, our ChemLoc™, FireLoc™, and SuperLoc™ lines
            are acceptable for use as pallet and tote storage buildings. Choose
            from a wide range of optional accessories to increase production
            turnover and employee safety.
          </p>
        </div> -->
        <!-- 8 -->
        <!-- <div class="appliitem row-one">
          <div class="imgbox">
            <img src="../../assets/image/8.png" alt="" />
          </div>
          <h2>Rental Hazmat Storage Buildings</h2>
          <p>
            Certain projects, sites and budgets make renting a hazmat storage
            building the smart solution. Some projects, such as industrial
            renovations, construction sites, demolitions and more, may not
            require a permanent chemical storage solution. Other storage needs
            may be seasonal.
          </p>
        </div> -->
        <div class="appliitem row-two">
          <div class="imgbox">
            <img src="../../assets/image/9.webp" alt="" />
          </div>
          <h2>Specialized Storage Buildings</h2>
          <p>
            We offer storage solutions engineered to fit your specific needs.
            Our specialized storage buildings are 100% customizable and provide
            the same attention to detail and quality manufacturing processes our
            company has become known for.
          </p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      observers: [],
      isFade:false
    };
  },
  methods: {
    isElementInViewport(element) {
      var rect = element.getBoundingClientRect();
      const isInViewport =
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight);
      return isInViewport;
    },
    checkHasAttribute(element) {
      return !!element.getAttribute("snow_is_show");
    },
    initFirstScreen() {
      const main = document.querySelectorAll(".actionBox div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        if (this.isElementInViewport(item)) {
          item.setAttribute("snow_is_show", true);
        }
      });
    },
    animateFn() {
      const main = document.querySelectorAll(".actionBox div") || [];
      const paragraphs = [...(main || [])];
      paragraphs.forEach((item) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.checkHasAttribute(item)) {
              // 元素进入视口
              item.classList.add("animate__animated");
              item.classList.add("animate__fadeInUp");
              item.setAttribute("snow_is_show", true);
            }
          });
        });
        observer.observe(item);
        this.observers.push(observer);
      });
    },
    // 清空所有 observer 的函数
    destructionObserver() {
      observers.forEach((observe) => {
        observe.disconnect();
      });
      observers.length = 0;
    },
    bgAction() {
      window.addEventListener('DOMContentLoaded', function () {
        var image = document.querySelector('.fade-in'); // 获取图片元素
        setTimeout(function () {
          this.isFade=true
        }, 500); // 延迟500ms后才开始显示图片
      });
      this.isFade=false
    }
  },
  created(){},
  mounted() {
    this.initFirstScreen();
    this.animateFn();
    this.bgAction()
  },
  watch: {
    $route: function (newVal) {
      this.destructionObserver(); // 先清空所有的观察者
      this.initFirstScreen(); // 再初始化一次 类似onMounted
      this.animateFn(); // 再次执行核心函数
    },
  },
};
</script>

<style scoped>
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
  
    /* 起始位置 */
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  
    /* 结束位置 */
  }
  
  .fade-in {
    animation: fadeIn 2s ease forwards;
    width: 100%;
    background: url("https://greencitizen.com/wp-content/uploads/2023/11/lithium-ion-battery-for-EV.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    height: 100%;
  }
.storage {
  width: 1250px;
  /* background-color: rgb(204, 224, 180); */
  margin: 0 auto;
  /* .logo {
    width: 1250px;
    height: 112px;
    margin: 0 auto;
    img {
      margin-top: 15px;
    }
  } */
  /* 产品 */
  .application {
    height: 530px;

    h1 {
      margin-top: 40px;
      color: rgb(24, 141, 196);
      font-family: 'barlow';
    }

    p {
      font-weight: 300;
      line-height: 1.4em;
      margin-left: 10px;
      font-family: 'barlow';
    }
    h2 {
      text-align: center;
      margin: 35px 0;
      font-family: 'barlow';
    }
    h6 {
      font-weight: 300;
      line-height: 1.5em;
      margin-left: 10px;
      font-size: 16px;
      text-align: center;
      font-family: 'barlow';
    }
    .buliding {
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      .construction:hover {
        transform: scale(1.2);
      }
      .construction {
        width: 300px;
        height: 340px;
        background-color: #e3f4ff;
        border-radius: 10%;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        transition: 5s;

        h2 {
          font-size: 22px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
          letter-spacing: 0.5px;
          font-family: "customfont";
          color: rgb(24, 141, 196);
          margin: 25px 0;
          font-family: 'barlow';
        }
        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.8em;
          letter-spacing: 0.5px;
          word-spacing: 0.5px;
          font-family: 'barlow';
        }
      }
    }
  }

  /* 所有产品 */
  .Allapplication {
    height: 600px;
    /* background-color: aqua; */
    h1 {
      text-align: center;
      color: rgb(24, 141, 196);
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: 600;
      margin: 50px 0;
      font-family: 'barlow';
    }
    .useflex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .appliitem:hover {
        transform: scale(1.2);
      }
      /* /* .row-one{
        background-color: #def7e6;
        } */
      .row-two {
        background-color: #e3f4ff;
      }
      .appliitem {
        width: 400px;
        height: 450px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        border-radius: 10%;
        margin-bottom: 10px;
        transition: 3s;
        .imgbox {
          overflow: hidden;
          img {
            width: 200px;
            height: 200px;
            text-align: center;
            border-radius: 50%;
            overflow: hidden;
            display: block;
            margin: auto;
            padding-top: 10px;
          }
        }
        h2 {
          font-size: 20px;
          line-height: 1.4em;
          font-weight: 500;
          color: rgb(65, 108, 51);
          text-align: center;
          font-family: 'barlow';
        }
        p {
          font-size: 16px;
          line-height: 1.6em;
          font-weight: 300;
          text-align: center;
          font-family: 'barlow';
        }
      }
    }
  }
}
</style>
