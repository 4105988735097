import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home/index.vue'
import Recovery from '../views/Recovery/index.vue'
import Storage from '../views/Storage/index.vue'
import Transport from '../views/Transport/index.vue'
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerRePush.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const routes = [
 {
  path:'/',
  name:'home',
  component:Home
 },{
  path:'/recovrey',
  name:'recovrey',
  component:Recovery
 },{
  path:'/storage',
  name:'storage',
  component:Storage
 },{
  path:'/transport',
  name:'transport',
  component:Transport
 }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
